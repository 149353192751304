:root {
    --color-primary: #3366FF;
    --color-secondary: #264070;
    --color-bg: #EDF3FF;
    --font-primary: 'Rubik', system-ui, -apple-system, 'Roboto', sans-serif;
}

body {
    background-image: url('../images/bg-auth.png');
    background-size: auto;
}

.ml-2 {
    margin-left: 20px !important;
}

.mt-5 {
    margin-top: 35px !important;
}

.auth-box {
    border: 1px solid rgb(255 255 255 / 23%);
    padding: 15px;
    background-color: rgb(255 255 255 / 23%);
}

.text-right {
    text-align: right !important;
}

.dc-count {
    font: normal normal 50px/76px "Roboto", "Helvetica", "Arial", sans-serif !important;
}

a {
    text-decoration: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.link {
    color: #52ABE8 !important;
}

.verified {
    color: #2ecc71;
}

.un-verified {
    color: #e74c3c;
}

.verified {
    color: #2ecc71;
}

.un-verified {
    color: #e74c3c;
}

.card {
    padding: 15px;
    background-color: #424242;
    box-shadow: 0px 3px 60px #586EB529;
    border-radius: 9px;
    border: none;
}

.drop-area svg {
    color: var(--color-primary);
}

.drop-area {
    width: 100%;
    border: 2px dashed var(--color-primary);
    background-color: #00000070;
    border-radius: 5px;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.suggested-word:hover {
    background-color: #dedede;

}